<template>
    <footer>
      <p>&copy; 2024 PrintMyWall. All rights reserved.</p>
    </footer>
  </template>
  
  <script>
  export default {
    name: 'AppFooter'
  }
  </script>
  
  <style scoped>
  footer {
    background-color: #f8f8f8;
    padding: 10px;
    text-align: center;
  }
  </style>