<template>
  <div class="content-section">
    <div class="hero-section">
      <img src="@/assets/hero-image4.jpg" alt="Hero Image" class="hero-image">
      <div class="hero-text">
        <h1>Transform Your Space with Custom Wall Art</h1>
        <p>Where Your Walls Tell Your Story</p>
        <button class="get-quote-button" @click="scrollToQuote">Get Quote</button>
      </div>
      <div class="scroll-indicator">
        <span></span>
      </div>
    </div>
   <!-- Our Service Section -->
  
    <our-service-section></our-service-section>
    <!-- Testimonials section -->
    <TestimonialSlider/>

    <!-- Statistics Section -->
    <statistics-section></statistics-section>

    <!-- Before and After Section -->
    <before-after-section></before-after-section>

       <!-- About Us Section -->
    <!-- <about-us-section></about-us-section> -->

    <!-- Форма запроса цены -->
    <request-quote-form></request-quote-form>
      <!-- Секция Контактов -->
      <contact-section></contact-section>
  </div>
</template>

<script>
// import OurService from './OurService.vue';
import OurServiceSection from './OurServiceSection.vue';
import StatisticsSection from './StatisticsSection.vue';
import TestimonialSlider from './TestimonialSlider.vue';
import BeforeAfterSection from './BeforeAfterSection.vue';
import RequestQuoteForm from './RequestQuoteForm.vue';
import ContactSection from './ContactSection.vue';
// import AboutUsSection from './AboutUsSection.vue';

export default {
  name: 'AppContent',
  components: {
 
    OurServiceSection,
    StatisticsSection,
    TestimonialSlider,
    BeforeAfterSection,
    RequestQuoteForm,
    ContactSection
  },
  methods: {
    scrollToQuote() {
      const element = document.getElementById('request-quote');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }
}
</script>

<style scoped>

.content-section {
  background-color: #f8f8f8;
}
/* Секция hero */
.hero-section {
  position: relative;
  height: 100vh; /* Полная высота экрана */
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden;
  background-color: transparent;
 
  z-index: 2;
}

.hero-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.hero-text {
  color: white;
  z-index: 1;
  padding: 20px;
  background: rgba(34, 34, 34, 0.5); /* Слегка темный фон для читаемости текста */
  border-radius: 10px; /* Закругленные углы */
}

.hero-text h1 {
  font-size: 3rem; /* Настройте размер по необходимости */
  margin-bottom: 10px;
}

.hero-text p {
  font-size: 1.5rem; /* Настройте размер по необходимости */
  margin-bottom: 20px;
}

.get-quote-button {
  background-color: #F37C1F;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.get-quote-button:hover {
  background-color: #e67e22; /* Более темный оттенок при наведении */
}

.scroll-indicator {
  position: absolute;
  bottom: 20px;
  width: 100%;
  text-align: center;
}

.scroll-indicator span {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-bottom: 2px solid white;
  border-right: 2px solid white;
  transform: rotate(45deg);
  animation: bounce 2s infinite;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0) rotate(45deg);
  }
  40% {
    transform: translateY(10px) rotate(45deg);
  }
  60% {
    transform: translateY(5px) rotate(45deg);
  }
}

/* Секция отзывов клиентов */
.testimonial-section {
  text-align: center;
  padding: 50px 20px;
  background-color: #f8f8f8;
}

h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.testimonial-slider-container {
  overflow: hidden; /* Скрываем выходящий за пределы слайда контент */
  width: 100%;
}

.testimonial-slider {
  display: flex;
  transition: transform 0.5s ease;
  width: 100%; /* Задает ширину контейнера в 100% */
}

.testimonial-group {
  display: flex;
  justify-content: space-around;
  width: 100%; /* Ширина каждого слайда, показывающего два отзыва */
  flex-shrink: 0; /* Предотвращает сжатие группы */
}

.testimonial {
  width: 45%; /* Ширина каждого отзыва на слайде */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.testimonial-content {
  font-size: 1.25rem;
  margin-bottom: 20px;
  position: relative;
}

.quote-icon {
  font-size: 3rem;
  color: #F37C1F;
}

.testimonial-author {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.testimonial-author img {
  width: 60px;
  height: 60px;
  margin-bottom: 10px;
}

/* Кнопки для переключения слайдов */
.slider-controls {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.control-button {
  width: 15px;
  height: 15px;
  background-color: rgba(255, 255, 255, 0.5);
  margin: 0 5px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
}

.control-button.active {
  background-color: #F37C1F;
}
</style>
