<template>
  <div id="contact-section" class="contact-section-wrapper">
    <div class="divider"></div> <!-- Полоса-разделитель -->
    <div class="contact-section">
      <h2>Contact Us</h2>
      <p>We'd love to hear from you! Reach out to us with any questions or to start your project.</p>

      <div class="contact-details">
        <div class="contact-item">
          <img src="@/assets/icons/Email.png" alt="Email Icon" class="contact-icon">
          <h3>Email Us</h3>
          <p><a href="mailto:info@printmywall.ca" class="contact-link">info@printmywall.ca</a></p>
        </div>
        <div class="contact-item">
          <img src="@/assets/icons/Call.png" alt="Phone Icon" class="contact-icon">
          <h3>Call Us</h3>
          <p><a href="tel:+18257340507" class="contact-link">+1 825 734 0507</a></p>
        </div>
        <div class="contact-item">
          <img src="@/assets/icons/Location.png" alt="Location Icon" class="contact-icon">
          <h3>Visit Us</h3>
          <p>Sage Hill Road, Calgary, AB T3R1W8</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContactSection",
  data() {
    return {
      form: {
        name: '',
        email: '',
        message: ''
      }
    };
  },
  methods: {
    submitForm() {
      // Логика отправки формы
      alert('Your message has been sent!');
      this.form.name = '';
      this.form.email = '';
      this.form.message = '';
    }
  }
}
</script>

<style scoped>
.divider {
  width: 80%;
  height: 2px;
  background-color: #ccc;
  margin: 0 auto 20px;
}

.contact-section {
 
  background-color: #f8f8f8;
  text-align: center;
}

.contact-section h2 {
  font-size: 2rem;
  margin-bottom: 10px;
}

.contact-section p {
  font-size: 1rem;
  margin-bottom: 10px;
}

.contact-details {
  display: flex;
  justify-content: space-around;
  margin-bottom: 30px;
  flex-wrap: wrap;
}

.contact-item {
  margin-bottom: 10px;
  width: 100%;
  max-width: 300px;
  text-align: center;
  position: relative;
}

.contact-item h3 {
  font-size: 1.5rem;
  margin-bottom: 5px;
}

.contact-item p {
  font-size: 1.25rem;
}

.contact-icon {
  width: 50px; /* Увеличенный размер иконки */
  height: 50px;
  margin-bottom: 10px;
}

.contact-link {
  color: #000000; /* Синий цвет для ссылок */
  text-decoration: none;
  transition: color 0.3s ease;
}

.contact-link:hover {
  color: #0056b3; /* Темный синий при наведении */
}

@media (max-width: 768px) {
  .contact-item {
    width: 80%;
    margin: 10px auto;
  }
}

@media (max-width: 480px) {
  .contact-item {
    width: 100%;
  }
}

.contact-form h3 {
  font-size: 2rem;
  margin-bottom: 20px;
}

.contact-form form {
  display: grid;
  gap: 15px;
  max-width: 600px;
  margin: 0 auto;
}

.contact-form input,
.contact-form textarea {
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.contact-form button {
  padding: 12px 20px;
  font-size: 1rem;
  color: #fff;
  background-color: #F37C1F;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.contact-form button:hover {
  background-color: #e66900;
}

.map {
  margin-top: 40px;
}
</style>
