<template>
  <header>
    <div class="logo">
      <img src="@/assets/logo2.png" alt="Logo">
    </div>
    <nav>
      <ul :class="['menu', { open: isMenuOpen }]">
        <li><a href="#" @click.prevent="scrollToTop">Home</a></li>        
        <li><a href="#" @click.prevent="scrollToAboutUs">About Us</a></li>
        <li><a href="#"  @click.prevent="scrollToContact">Contact</a></li>
        <!-- <li><a href="#">FAQs</a></li>
        <li><a href="#">Pricing</a></li> -->
      </ul>
      <button class="menu-toggle" @click="toggleMenu">
        <span class="bar"></span>
        <span class="bar"></span>
        <span class="bar"></span>
      </button>
    </nav>
  </header>
</template>

<script>
export default {
  name: 'AppHeader',
  data() {
    return {
      isMenuOpen: false,
    };
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
      const menu = this.$el.querySelector('.menu');
      if (this.isMenuOpen) {
        menu.style.maxHeight = menu.scrollHeight + 'px';
        window.addEventListener('scroll', this.handleScroll);
      } else {
        menu.style.maxHeight = '0';
        window.removeEventListener('scroll', this.handleScroll);
      }
    },
    handleScroll() {
      if (this.isMenuOpen) {
        this.toggleMenu(); // Закрыть меню при прокрутке страницы
      }
    },
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' }); // Прокрутка к верху страницы
    },
    scrollToContact() {
      const contactSection = document.querySelector('#contact-section');
      if (contactSection) {
        contactSection.scrollIntoView({ behavior: 'smooth' }); // Прокрутка к секции ContactSection
      }
      
    },
    scrollToAboutUs() {
      const aboutUsSection = document.querySelector('#service-section');
      if (aboutUsSection) {
        aboutUsSection.scrollIntoView({ behavior: 'smooth' }); // Прокрутка к секции ContactSection
      }      
    }
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background: white;
  color: #333333;
  position: relative;
  z-index: 1000;
}

.logo img {
  height: 50px;
}

nav {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.menu {
  display: flex;
  list-style: none;
  transition: max-height 0.5s ease;
  overflow: hidden;
  flex-direction: row;
  background-color: transparent;
  max-height: none;
}

.menu > li {
  margin-right: 20px;
}

.menu > li > a {
  text-decoration: none;
  color: #333333;
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
  padding: 15px;
  display: block;
  transition: color 0.3s;
}

.menu > li > a:hover {
  color: #F37C1F;
}

.menu-toggle {
  display: none;
  flex-direction: column;
  justify-content: space-around;
  height: 24px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
}

.menu-toggle .bar {
  width: 30px;
  height: 3px;
  background-color: #333333;
  border-radius: 5px;
  transition: all 0.3s ease;
}

@media (max-width: 768px) {
  .menu {
    flex-direction: column;
    position: absolute;
    top: 100%;
    right: 0;
    width: 100%;
    background-color: #F7F1E3;
    max-height: 0;
    overflow: hidden;
    z-index: 9999;
  }

  .menu.open {
    max-height: 500px;
  }

  .menu > li {
    margin: 0;
    border-bottom: 1px solid #CCCCCC;
    width: 100%;
    text-align: center;
  }

  .menu-toggle {
    display: flex;
    position: relative;
    right: 0;
    z-index: 1001;
  }
}
</style>
