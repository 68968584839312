<template>
 <div class="testimonial-section">
        <h2>What Our Clients Say About Us</h2>
        <div class="testimonial-slider-container">
          <div class="testimonial-slider" :style="{ transform: `translateX(-${currentSlide * 100}%)` }">
            <div class="testimonial-group" v-for="(group, index) in groupedTestimonials" :key="index">
              <div class="testimonial" v-for="testimonial in group" :key="testimonial.name">
                <div class="testimonial-content">
                  <div class="quote-icon">“</div>
                  <p>{{ testimonial.text }}</p>
                </div>
                <div class="testimonial-author">
                  <img src="@/assets/icon-h1-001.svg" alt="Client Icon" />
                  <h3>{{ testimonial.name }}</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="slider-controls">
          <div
            v-for="(group, index) in groupedTestimonials"
            :key="index"
            class="control-button"
            :class="{ active: currentSlide === index }"
            @click="goToSlide(index)"
          ></div>
        </div>
      </div>
      </template>
      <script>
       export default {
        data() {
      return {
        testimonials: [
          {
            text: "We recently acquired one of your wall printers for our office and we are extremely pleased with the outcome. The print quality is exceptional and the installation process was a seamless experience.",
            name: "Herbie Sanders"
          },
          {
            text: "Your wall printers have been a game changer for our conference rooms. They not only add a professional touch to the space, but they have also greatly facilitated our ability to collaborate and exchange ideas.",
            name: "Callan Welch"
          },
          {
            text: "Our team loves the new look of our workspace. The custom wall art has made a huge difference in creating a motivating environment.",
            name: "Jamie Lee"
          },
          {
            text: "The installation process was smooth, and the results are breathtaking. The quality of the wall print is unmatched.",
            name: "Morgan Brown"
          },
          {
            text: "Thank you for transforming our office space! The wall art adds so much character and really sets the tone for our brand.",
            name: "Taylor Smith"
          },
          {
            text: "Exceptional service and amazing quality! The wall prints have brought our ideas to life in ways we couldn’t imagine.",
            name: "Alex Johnson"
          }
        ],
        currentSlide: 0
      };
    },
    computed: {
      groupedTestimonials() {
        return this.testimonials.reduce((result, testimonial, index) => {
          const groupIndex = Math.floor(index / 2);
          if (!result[groupIndex]) {
            result[groupIndex] = [];
          }
          result[groupIndex].push(testimonial);
          return result;
        }, []);
      }
    },
    mounted() {
      this.startSlider();
    },
    methods: {
      startSlider() {
        this.interval = setInterval(() => {
          this.nextSlide();
        }, 3000); // Переключение слайдов каждые 3 секунды
      },
      nextSlide() {
        this.currentSlide = (this.currentSlide + 1) % this.groupedTestimonials.length;
      },
      goToSlide(index) {
        this.currentSlide = index;
        clearInterval(this.interval); // Остановить автоматическое переключение при ручном выборе
        this.startSlider(); // Перезапуск автоматического переключения
      }
    },
    beforeUnmount() {
      clearInterval(this.interval); // Очистка интервала при уничтожении компонента
    }
       }
    </script>
    <style>
  .testimonial-section {
    text-align: center;
    padding: 50px 20px;
    background-color: #f8f8f8;
   
  }
  
  h2 {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
  
  .testimonial-slider-container {
    overflow: hidden; /* Скрываем выходящий за пределы слайда контент */
    width: 100%;
  }
  
  .testimonial-slider {
    display: flex;
    transition: transform 0.5s ease;
    width: 100%; /* Задает ширину контейнера в 100% */
  }
  
  .testimonial-group {
    display: flex;
    justify-content: space-around;
    width: 100%; /* Ширина каждого слайда, показывающего два отзыва */
    flex-shrink: 0; /* Предотвращает сжатие группы */
  }
  
  .testimonial {
    width: 45%; /* Ширина каждого отзыва на слайде */
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .testimonial-content {
    font-size: 1.25rem;
    margin-bottom: 20px;
    position: relative;
  }
  
  .quote-icon {
    font-size: 3rem;
    color: #F37C1F;
  }
  
  .testimonial-author {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  
  .testimonial-author img {
    width: 60px;
    height: 60px;
    margin-bottom: 10px;
  }
  
  /* Кнопки для переключения слайдов */
  .slider-controls {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .control-button {
    width: 15px;
    height: 15px;
    background-color: rgba(126, 126, 126, 0.5);
    margin: 0 5px;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .control-button.active {
    background-color: #F37C1F;
  }
  </style>