<template>
  <div class="statistics-container">
    <h2 class="section-title">What we do</h2>
    <div class="statistics-section">
      <div
        class="statistic-item"
        v-for="(stat, index) in statistics"
        :key="index"
        @mouseover="hoveredIndex = index"
        @mouseleave="hoveredIndex = null"
        :class="{ active: hoveredIndex === index }"
      >
        <div class="icon-container">
          <img :src="stat.icon" alt="Statistic Icon" class="statistic-icon" />
        </div>
        <div class="statistic-value">{{ stat.value }}</div>
        <div class="statistic-label">{{ stat.label }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StatisticsSection',
  data() {
    return {
      hoveredIndex: null,
      statistics: [
        { icon: require('@/assets/icons/Local_customer.png'), value: 26, label: 'Local Customers' },
        { icon: require('@/assets/icons/Printed.png'), value: '+249', label: 'Printed Walls' },
        { icon: require('@/assets/icons/LiveSupport.png'), value: '7 - 24', label: 'Live Support' },
        { icon: require('@/assets/icons/experience.png'), value: 4, label: 'Years Experience' }
      ]
    };
  }
}
</script>

<style scoped>
.statistics-container {
  text-align: center;
  margin: 50px 0;
  background-color: #f8f8f8;

}

.section-title {
  font-size: 2.5rem;
  margin-bottom: 40px;
}

.statistics-section {
  display: flex;
  justify-content: center; /* Выравнивание по центру */
  flex-wrap: wrap; /* Позволяет элементам переноситься на следующую строку на маленьких экранах */
  gap: 20px; /* Добавляет отступы между элементами */
}

.statistic-item {
  background-color: #fff;
  border: 2px solid rgba(126, 126, 126, 0.5);
  padding: 30px;
  width: 20%; /* Увеличиваем ширину для лучшего отображения */
  text-align: center;
  transition: border-color 0.3s;
  cursor: pointer;
}

@media (max-width: 768px) {
  .statistic-item {
    width: 45%; /* Уменьшаем ширину элемента на мобильных устройствах */
    margin-bottom: 20px;
  }
}

@media (max-width: 480px) {
  .statistic-item {
    width: 90%; /* Занимает всю ширину экрана на самых маленьких экранах */
  }
}

.statistic-item.active {
  border-color: #F37C1F;
}

.icon-container {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
}

.statistic-icon {
  height: 60px;
  transition: transform 0.3s;
}

.statistic-item.active .statistic-icon {
  transform: rotate(360deg);
}

.statistic-value {
  font-size: 2.5rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.statistic-label {
  font-size: 1.2rem;
  color: #777;
}
</style>
