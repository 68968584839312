<template>
  <div id="service-section" class="our-service-section">
    <h2>Our Service</h2>
    <p>We specialize in transforming ordinary walls into extraordinary pieces of art. Whether it's your home, office, restaurant, or any other space, we can print your favorite photographs or custom designs directly onto walls and various surfaces.</p>

    <div class="service-content">
      <div class="service-item">
        <h3>Custom Wall Art</h3>
        <p>We print high-quality images on walls, creating a personalized atmosphere that reflects your taste and style. From family portraits to scenic landscapes, the possibilities are endless.</p>
      </div>

      <div class="service-item">
        <h3>Versatile Surfaces</h3>
        <p>Our advanced printing technology allows us to print on a variety of surfaces, including concrete, wood, glass, and more. No matter the material, we ensure vibrant and long-lasting results.</p>
      </div>

      <div class="service-item">
        <h3>Applications</h3>
        <p>Our services are perfect for any environment. Whether you're looking to enhance your living room, create an inspiring workspace in your office, or add a unique touch to a restaurant, we've got you covered.</p>
      </div>
    </div>

    <!-- YouTube Video Section -->
    <div class="video-section">
      <h3>Watch Our Work in Action</h3>
      <div class="video-container">
        <iframe 
          width="560" 
          height="315" 
          srcdoc="<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto;height:100%;object-fit:cover}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style><a href=https://www.youtube.com/embed/1lPX5V3tAb0?autoplay=1><img src=https://img.youtube.com/vi/1lPX5V3tAb0/hqdefault.jpg alt='Watch video'><span>▶</span></a>" 
          title="YouTube video player" 
          frameborder="0" 
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
          allowfullscreen>
        </iframe>
      </div>
    </div>

    <!-- <div class="service-images">
      <img src="@/assets/service-image1.jpg" alt="Home Wall Art">
      <img src="@/assets/service-image2.jpg" alt="Office Wall Art">
      <img src="@/assets/service-image3.jpg" alt="Restaurant Wall Art">
    </div> -->
  </div>
</template>

<script>
export default {
  name: "OurServiceSection"
};
</script>

<style scoped>
.our-service-section {
  padding: 40px 20px;
  background-color: #f8f8f8;
  text-align: center;
}

.our-service-section h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.our-service-section p {
  font-size: 1.2rem;
  margin-bottom: 30px;
  color: #555;
}

.service-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 40px;
}

.service-item {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: left;
}

.service-item h3 {
  font-size: 2rem;
  margin-bottom: 15px;
  color: #333;
}

.service-item p {
  font-size: 1.1rem;
  color: #666;
}

.video-section {
  margin-top: 40px;
  text-align: center;
}

.video-section h3 {
  font-size: 2rem;
  margin-bottom: 20px;
}

.video-container {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 Aspect Ratio */
  height: 0;
  overflow: hidden;
  max-width: 100%;
  background: #000;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

@media (min-width: 768px) {
  .service-content {
    flex-direction: row;
    justify-content: space-between;
  }

  .service-item {
    flex: 1;
    margin: 0 10px;
  }
}

.service-images {
  display: flex;
  justify-content: space-around;
  gap: 20px;
}

.service-images img {
  width: 100%;
  max-width: 300px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
</style>
