<template>
  <div class="before-after-section">
    <!-- Заголовок и описание секции -->
    <div class="section-header">
      <h2>Transform Your Space with Precision</h2>
      <p class="section-description">
        Using advanced UV printing techniques, we bring your walls to life with sharp, detailed imagery that stands the test of time. Our eco-conscious, odor-free inks ensure that your environment remains safe and comfortable while showcasing stunning visuals that resist fading for years.
      </p>
    </div>
    
    <!-- Сетка изображений с ползунками -->
    <div class="before-after-grid">
      <div v-for="(image, index) in images" :key="index" class="before-after-container">
        <img :src="image.before" alt="Before Image" class="before-image" />
        <img :src="image.after" alt="After Image" class="after-image" :style="clipStyle(index)" />
        <div class="slider-line" :style="{ left: sliders[index] + '%' }">
          <div class="slider-handle">
            <span class="arrow left">◀</span>
            <span class="arrow right">▶</span>
          </div>
        </div>
        <input type="range" v-model="sliders[index]" min="0" max="100" class="slider-input" @input="updateSlider(index, $event)" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BeforeAfterSection",
  data() {
    return {
      sliders: [50, 50, 50, 50], 
      images: [
        {
          before: require('@/assets/image1_before.jpg'),
          after: require('@/assets/image1_after.jpg'),
        },
        {
          before: require('@/assets/image2_before.jpg'),
          after: require('@/assets/image2_after.jpg'),
        },
      ],
    };
  },
  methods: {
    clipStyle(index) {
      return {
        clipPath: `inset(0 0 0 ${this.sliders[index]}%)`
      };
    },
    updateSlider(index, event) {
      this.sliders[index] = event.target.value;
    }
  }
}
</script>

<style scoped>
.before-after-section {
  background-color: #f8f8f8;
  padding: 40px 20px;
  max-width: 1200px; /* Уменьшенная ширина секции */
  margin: 0 auto; /* Центрирование секции */
}

.section-header {
  text-align: center;
  margin-bottom: 40px;
}

.section-header h2 {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 10px;
}

.section-description {
  font-size: 1rem;
  color: #777;
  max-width: 600px;
  margin: 0 auto;
}

.before-after-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Две колонки */
  gap: 20px; /* Отступы между изображениями */
}

@media (max-width: 768px) {
  .before-after-grid {
    grid-template-columns: 1fr; /* Одна колонка на мобильных устройствах */
  }
}

.before-after-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 300px; 
  border: 1px solid #ccc;
}

.before-image,
.after-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.slider-line {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 2px;
  background-color: rgba(255, 255, 255, 0.5); /* Прозрачная линия */
  cursor: pointer;
  z-index: 15;
}

.slider-handle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 40px;
  height: 40px;
  background-color: rgba(255, 255, 255, 0.5); /* Прозрачный круг */
  border: 1px solid rgba(255, 255, 255, 0.8); /* Прозрачная граница */
  border-radius: 50%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.arrow {
  font-size: 16px;
  user-select: none;
  color: rgba(0, 0, 0, 0.7); /* Полупрозрачные стрелки */
}

.slider-input {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 20;
}
</style>
